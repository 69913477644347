import React from "react"
import styled from "styled-components";

import { BodyText, PageContainer } from "../recipes";

import Layout from "../components/Layout"
import SEO from "../components/SEO"

const Contacts = styled.div`
  display: flex;
  flex-direction: column;
`;

const Contact = styled.div`
  display: flex;
  font-family: ${props => props.theme.baseFont};
  font-size: ${props => props.theme.fontSizeSmall};
  padding: ${props => props.theme.spacingSmall} ${props => props.theme.spacingTiny};
  word-break: break-all;

  @media (min-width: ${props => props.theme.breakSmall}) {
    font-size: ${props => props.theme.fontSizeRegular};
  }
`;

const ContactIcon = styled.div`
  font-weight: ${props => props.theme.fontWeightBold};
  width: ${props => props.theme.spacingRegular};
  margin-right: ${props => props.theme.spacingSmall};
`;

const ContactLink = styled.a`
  color: ${props => props.theme.textPrimary};
`;

const ContactDescription = styled.div``;

const Page = () => (
  <Layout>
    <SEO title="Contact" />
    <PageContainer>
      <PageContainer.Title text="contact.title" />
      <BodyText>
        Feel free to contact me for any enquiries or orders. Slide into my DMs, call or email me; use whatever mechanism is easiest for you!
      </BodyText>
      <Contacts>
        <Contact>
          <ContactIcon>@</ContactIcon>
          <ContactDescription><ContactLink href="https://instagram.com/jonesingforcake" target="_blank">jonesingforcake</ContactLink></ContactDescription>
        </Contact>
        <Contact>
          <ContactIcon><span role="img" aria-label="email" target="_top">✉️</span></ContactIcon>
          <ContactDescription><ContactLink href="mailto:hola@jonesingforcake.com.au">hola@jonesingforcake.com.au</ContactLink></ContactDescription>
        </Contact>
        <Contact>
          <ContactIcon><span role="img" aria-label="phone" target="_top">📞</span></ContactIcon>
          <ContactDescription><ContactLink href="tel:0437208887">0437 208 887</ContactLink></ContactDescription>
        </Contact>
        <Contact>
          <ContactIcon><span role="img" aria-label="location">📍</span></ContactIcon>
          <ContactDescription>Heathridge, Western Australia</ContactDescription>
        </Contact>
      </Contacts>
    </PageContainer>
  </Layout>
);

export default Page;
